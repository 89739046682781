/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Swagger";
  src: url("./fonts/SDSwaggerTTF.ttf");
  font-weight: normal;
  font-style: normal;
}

@layer utilities {
  @variants responsive {
    .text-shadow {
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
    }
    .text-shadow-md {
      text-shadow: 4px 4px 8px black;
    }
    .text-shadow-5 {
      text-shadow: 0 0 6px rgb(0, 0, 0);
    }
    .text-shadow-lg {
      text-shadow: 15px 15px 30px black;
    }
    .text-shadow-none {
      text-shadow: none;
    }
  }
}

.table-fixed {
  table-layout: fixed;
}
