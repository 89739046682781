.tooltip {
  position: absolute;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 101px;
  height: 30px;
  background-color: #6e3ae6;
  text-align: center;
  border-radius: 6px;
  /* padding: 5px 0; */
  padding-top: 6px;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  margin-left: -70px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 49.1%;
  margin-left: 23px;
  border-width: 8px;
  border-style: solid;
  border-color: #6e3ae6 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip2 {
  position: relative;
  display: inline-block;
}

.tooltip2 .tooltiptext2 {
  visibility: hidden;
  width: 101px;
  height: 30px;
  background-color: #6e3ae6;
  text-align: center;
  border-radius: 6px;
  /* padding: 5px 0; */
  padding-top: 6px;
  position: absolute;
  /* z-index: 1; */
  bottom: 150%;
  margin-left: -71px;
}

.tooltip2 .tooltiptext2::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 49.1%;
  margin-left: 23px;
  border-width: 8px;
  border-style: solid;
  border-color: #6e3ae6 transparent transparent transparent;
}

.tooltip2:hover .tooltiptext2 {
  visibility: visible;
}

.blink_style {
  -webkit-animation: blink 2s infinite both;
  animation: blink 2s infinite both;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}


.accordion {
  background: #ffffff;
}
.accordion.can-hover:hover {
  background: #946cf0 !important;
}
.accordion p {
  color: #444 ;
}
.accordion.can-hover:hover p.hover {
  color: #fff !important;
}
.accordion.can-hover:hover img.hover{
 filter: brightness(0) invert(1)
}
.accordion .dividing-line {
  background-color: #e0e0e0;
}
.accordion.can-hover:hover .accordion .dividing-line {
  background-color: #ac8afa;
}



.card-btn:hover {
  background-color: #936cee;
}
.card-btn:hover p {
  color: #fff !important;
}






