.collapsible-content-edonec {
  overflow: hidden;
  transition: height 0.4s ease-in-out;
}
.collapsible-content-container {
  transition: opacity 0.4s ease-in-out;
}
.collapsible-content-edonec-disable-close {
  overflow: hidden;
  /* transition: height 0.2s ease-in-out; */
}