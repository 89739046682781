
  
input[type=checkbox] {
    border: 1px solid #41403f;
    border-radius: 4px;
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    cursor: pointer;
    background: #281d45 center center;
}
  
.bg-gray-1f1f1e input[type=checkbox] {
    border: 1px solid #323232;
    background: #323232 center center;
}
input[type=checkbox]:checked {
  background: #1e1e1e url(../../images/check_on.png) no-repeat center center
}

input[type=checkbox]:hover:checked {
  background: #1e1e1e url(../../images/check_on.png) no-repeat center center

}

/* input[type="checkbox"]:hover  {
  background: transparent url(https://i.imgur.com/O17y09P.png) no-repeat center center
} */

  
input[type=radio]:checked {
   background: #1e1e1e url(../../images/check_on.png) no-repeat center center
}

input[type=radio]:hover:checked {
  background: transparent url(https://i.imgur.com/jcEenus.png) no-repeat center center
}

input[type="radio"]:hover  {
  background: transparent url(https://i.imgur.com/O17y09P.png) no-repeat center center
}

input[type=radio] {
  border: 1px solid #1e1e1e;
  border-radius: 4px;
  background-color: #1e1e1e;
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  cursor: pointer;
  background: #1e1e1e center center;
}


input[type=checkbox]:hover input ~ .checkmark {
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: #a5a5a5;
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  cursor: pointer;
  background: #a5a5a5 center center;

  /* box-shadow:inset 0 0 0px 2px white; */

}



  
  