.blink_style {
  -webkit-animation: blink 2s infinite both;
  animation: blink 2s infinite both;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}
.hover-style.can-hover:not(.lock-cell):hover {
  background: linear-gradient(to top, #5423a0, #9d3bbb);
  color: #fff !important
}
.hover-style.can-hover:not(.lock-cell):hover p {
  color: #fff !important
}