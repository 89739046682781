/* Scrollbar styles */

.scroll-div::-webkit-scrollbar {
  width: 6px;
  height: 5px;
  display: block;
}

.scroll-div::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 10px #430674; */
  background: #430674;
  border-radius: 10px;
}

.scroll-div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #956df1;
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

.scroll-div2::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  display: block;
}

.scroll-div2::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 10px #430674; */
  background: #5e399a;
  border-radius: 10px;
}

.scroll-div2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #9b6bd4;
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

.scroll-div3::-webkit-scrollbar {
  width: 6px;
  height: 5px;
  display: block;
}

.scroll-div3::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 10px #430674; */
  background: #fff;
  border-radius: 10px;
}

.scroll-div3::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #956df1;
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

.scroll-div4::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  display: block;
}

.scroll-div4::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 10px #430674; */
  background: #5e399a;
  border-radius: 10px;
}

.scroll-div4::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #9b6bd4;
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

.scroll-div5::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: block;
}

.scroll-div5::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 10px #530674; */
  background: #fff;
  border-radius: 10px;
}

.scroll-div5::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #956df1;
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

.scroll-div6::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  display: block;
}

.scroll-div6::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 10px #530674; */
  background: #2d054d;
  border-radius: 10px;

}

.scroll-div6::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #7d65ff;
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}