.item {
    align-items: center;
    color: #454545;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    justify-content: center;
    width: 10px;
    height: 10px;
}

.item:hover  {
  filter: brightness(120%)
}
  
.disabled-page {
  color: #808e9b;
}
  
.active {
  background: linear-gradient(#1491fc, #0675db);
  border-radius: 4px;
  border: solid 1px #0070d9;
  box-shadow:inset 0 0 1px 1px #2ab0ed;
  color: white;
  width: 10px;
  height: 32px;
}
 
.active :hover {
  filter: brightness(100%)
}

.next {
    position: absolute;
    left: 183px;
    width: 32px;
    height: 32px;
}
  
.next:hover {
  filter:brightness(50%);
}

.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 170px;
}

.pagination-page {
    width: 160px;
}
  
.previous {
    height: 32px;
    position: absolute;
    width: 8px;
    left: -31px;
    top: 10px;
}

.previous:hover {
  filter:brightness(150%);
}