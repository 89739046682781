.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext1 {
  visibility: hidden;
  min-width: 170px;
  width: max-content;
  height: 40px;
  background-color: #6e3ae6;
  color: #fff;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 7px;
  right: 170%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tooltip1 .tooltiptext1::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -13px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent transparent #6e3ae6;
}
.tooltip1:hover .tooltiptext1 {
  visibility: visible;
}

input[type="checkbox"]:checked {
  background: #1e1e1e
    url("../../../images/nonLivePage/RightComponent/Check_On.png") no-repeat
    center center;
}
