/* width */
::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      /* TODO: remove this */
      display: none;
}


::-webkit-scrollbar-corner {
      background: #1e1e1e;
}

/* Track */
::-webkit-scrollbar-track {
      /* margin-top: 2px;
        margin-bottom: 2px; */
      background: #1e1e1e;
}



/* Handle */
::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
      background: #999;
      border-radius: 10px;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
      background: #5e399a;
}

::-webkit-scrollbar-thumb {
      background: #9b6bd4;
      border-radius: 10px;
}