.App {
  text-align: center;
  font-family: "spoqa_han_sans_neo";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #792fad;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #792fad !important;
}

/* Start Carousel Style */
.card_animation {
  display: flex;
}

.card_animation_slide {
  width: 184px !important;
  min-width: 184px !important;
  transition: all 0.5s;
}

.card_animation_slide_horizontal {
  width: 295px !important;
  min-width: 284px !important;
  transition: all 0.5s;
}

.card_animation_slide.animated-card {
  width: 484px !important;
  min-width: 484px !important;
}

/* .card_animation .card_animation_slide:not(:hover) {
  width: 184px !important;
  min-width: 184px !important;
} */

.animated_carousel .carousel_tray_wrapper {
  min-width: 1260px;
}

.animated_carousel .carousel_tray_wrapper_horizontal {
  min-width: 3000px;
}

.animated_carousel_tray_wrapper .carousel__slider-tray--horizontal {
  gap: 10px;
  padding-bottom: 10px;
  /* padding: 10px; */
}

.vertical_carousel .carousel_tray_wrapper {
  min-width: 252px;
}

.animated_carousel-multiple .carousel_tray_wrapper {
  width: 1270px;
}

.animated_carousel-sport .carousel_tray_wrapper {
  min-width: 1284px;
}

.vertical_carousel.slot_vertical_carousel .carousel_tray_wrapper {
  min-width: 295px;
}

/* carousel__slider-tray--horizontal */

.sport_carousel__slider-tray .carousel__slider-tray--horizontal {
  padding-top: 15px;
  padding-bottom: 15px;
  gap: 0px;
}

.live_carousel_tray_wrapper .carousel__slider-tray--horizontal {
  gap: 0px;
}

.carousel_tray_wrapper .carousel__slider-tray--horizontal {
  gap: 0px;
  padding-bottom: 10px;
}

/* End Carousel Style */

/* Start Dropdown Arrow Style */
.arrow_box {
  position: relative;
  margin-bottom: -1px;
  background-color: "#ededeb";
}

.arrow_box:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ededeb;
  border-width: 8px;
  margin-left: -10px;
}

/* End Dropdown Arrow Style */

/* Start Tabs Style */

.tab-item-animate {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 180px;
  height: 24px;
  transform-origin: 0 0;
  transition: transform 0.25s;
}

.tabs .tabs-item:first-child.active-tab ~ .tab-item-animate {
  /* transform: translateX(0) scaleX(0.333); */
  left: 0;
}

.tabs .tabs-item:nth-child(2).active-tab ~ .tab-item-animate {
  /* transform: translateX(33.333%) scaleX(0.333); */
  left: 100px;
}

.tabs .tabs-item:nth-child(3).active-tab ~ .tab-item-animate {
  /* transform: translateX(calc(33.333% * 2)) scaleX(0.333); */
  left: 200px;
}

/* End Tabs Style */

.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

.table-fixed {
  table-layout: fixed;
}
.gap-x-2px {
  column-gap: 2px;
}
.gap-y-3px {
  row-gap: 3px;
}

.hoverDisabled {
  pointer-events: 'none',
}